import { createContext, useContext, useEffect, useState } from "react";
import { navigateToUrl } from "single-spa";
import { local } from "./utils/storage";
import {
  ACCOUNT_TYPE_USER,
  BUSINESS_USER_TYPE,
  IAM_USER_TYPE,
  SSO_USER_TYPE,
} from "./constants/constants";
import Cookies from "js-cookie";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  // We can have our existing Auth logic here, just making sure that we have a single QueryClient.

  const [loggedIn, setLoggedIn] = useState(() =>
    JSON.parse(local.getItem("defense-station-loggedin"))
  );

  useEffect(() => {
    const loginListener = () => {
      setLoggedIn(true);
    };

    const addLogoutListener = () => {
      setLoggedIn(false);
    };

    // AuthManager.addLoginListener(loginListener);
    // AuthManager.addLogoutListener(addLogoutListener);

    // return () => {
    //   AuthManager.removeLoginListener(loginListener);
    //   AuthManager.removeLogoutListener(addLogoutListener);
    // };
  }, []);

  // Any user details we want can go here
  const value = {
    loggedIn,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export const useAuth = () => useContext(AuthContext);

export const redirectToLogin = (persistLastPage) => {
  console.log("Redirecting to login")
  if (local.getItem("redirectTo")) {
    return;
  }
  let redirectionURL = "/login";
  if (persistLastPage) {
    // redirectionURL = redirectionURL + "?redirectTo=" + window.location.href;
    if (window.location.pathname != "" && window.location.pathname != "/" && window.location.pathname != "/login") {
      local.setItem("redirectTo", window.location.href);
    }
  }
  window.location.href = redirectionURL;
};

const redirectToHome = async () => {
  const { getUserProfile } = await import("@defense-station/api-service");
  const data = await getUserProfile().catch((e) => {
    console.log(e);
  });
  const user = data?.data?.iam_v1_GetAuthInfo?.user;
  if (!user) {
    removeState();
    removeToken();
    return;
    // removeState();
    // removeToken();
    // window.location.href = "/login";
    // return;
  }
  // if (window.location.origin != process.env.MANAGEMENT_APP_ENDPOINT) {
  //   if (
  //     user?.user_type == IAM_USER_TYPE ||
  //     user?.user_type == BUSINESS_USER_TYPE
  //   ) {
  //     // navigateToUrl("/management-station");
  //     navigateToUrl("/polciy");
  //   } else if (user?.user_type == SSO_USER_TYPE) {
  //     navigateToUrl("/employee-station");
  //   }
  // } else {
  if (user?.account_type == ACCOUNT_TYPE_USER) {
    navigateToUrl("/sypher");
  } else {
    navigateToUrl("/policy");
  }
  // }
};

const InnerLogin = ({ children }) => {
  const loggedIn = isLoggedIn();
  if (loggedIn) {
    return children;
  } else {
    redirectToLogin(true);
    return null;
  }
};

export const LoggedInRoute = ({ children }) => (
  <AuthProvider>
    <InnerLogin>{children}</InnerLogin>
  </AuthProvider>
);

const InnerLogout = ({ children }) => {
  const loggedIn = isLoggedIn();
  if (loggedIn) {
    redirectToHome();
    return null;
  } else {
    return children;
  }
};

export const LoggedOutRoute = ({ children }) => (
  <AuthProvider>
    <InnerLogout>{children}</InnerLogout>
  </AuthProvider>
);

export const signIn = async (email, verify_token, captcha_code) => {
  try {
    const { loginRequest } = await import("@defense-station/api-service");
    const res = await loginRequest(email, verify_token, captcha_code);
    local.setItem("access-token", res?.data?.iam_v1_GetAuthToken?.auth_token);
    Cookies.set(
      "CloudFront-Policy",
      res?.data?.iam_v1_GetAuthToken?.cloudfront_policy,
      {
        domain: ".dev.defencestation.ca",
        path: "/",
      }
    );
    Cookies.set(
      "CloudFront-Signature",
      res?.data?.iam_v1_GetAuthToken?.cloudfront_signature,
      {
        domain: ".dev.defencestation.ca",
        path: "/",
      }
    );
    Cookies.set(
      "CloudFront-Key-Pair-Id",
      res?.data?.iam_v1_GetAuthToken?.cloudfront_key_pair_id,
      {
        domain: ".dev.defencestation.ca",
        path: "/",
      }
    );
    return res;
  } catch (e) {
    throw e;
  }
};

export const signInWithToken = async (token) => {
  try {
    local.setItem("access-token", token);
  } catch (e) {
    throw e;
  }
};

export const iamSignIn = async (
  account_id,
  username,
  verify_token,
  captcha_code
) => {
  try {
    const { iamLoginRequest } = await import("@defense-station/api-service");
    const res = await iamLoginRequest(
      account_id,
      username,
      verify_token,
      captcha_code
    );
    local.setItem("access-token", res?.data?.iam_v1_GetAuthToken?.auth_token);
    return res;
  } catch (e) {
    throw e;
  }
};

export const isLoggedIn = () => {
  return local.getItem("access-token") ? true : false;
};

export const signOut = async () => {
  try {
    // const { logoutRequest } = await import("@defense-station/api-service");
    // await logoutRequest();
    await local.removeItem("access-token");
  } catch (e) {
    await local.removeItem("access-token");
  }
};

export const removeToken = async () => {
  await local.removeItem("access-token");
};

export const setRedirectionBackActionForLogin = () => {
  local.setItem("redirect-back", true);
};

export const removeRedirectionBackActionForLogin = () => {
  local.removeItem("redirect-back");
};

export const isRedirectBackActionSet = () => local.getItem("redirect-back");

export const getAccessToken = () => local.getItem("access-token");

export const saveState = (value) => local.setItem("state", value);

export const removeState = () => local.removeItem("state");

export const getState = () => local.getItem("state");

export const signWithSSO = async ({ code, state }) => {
  try {
    const { loginSSORequest } = await import("@defense-station/api-service");
    const res = await loginSSORequest({ code, state });
    local.setItem("access-token", res?.data?.iam_v1_LoginSSO?.auth_token);
  } catch (e) {
    throw e;
  }
};
